import {state as encounterState} from './encounters';

export const type = {
	free: 'Free',
	bonus: 'Bonus',
	reserved: 'Reserved',
	completed: 'Completed',
};

export const salesmanVisitState = {
	...encounterState,
	contacted: 'Not offered',
};

export const ihSource = {
	installed: 'Installed building',
	installing: 'Environment of building currently being installed',
	offer: 'Offer utilization',
	call: 'Phone call',
	d2d: 'Door-to-door',
	cancelledDeal: 'Cancelled sale utilization',
	notContacted: 'Uncontacted',
	lead: 'Lead',
	customerLead: 'Customer lead',
};

export const projectState = {
	1: 'Visit 1',
	2: 'Visit 2',
	3: 'Info session',
	offer: 'Offer',
	noOffer: 'Not offered',
	cancelledOffer: 'Cancelled offer',
	deal: 'Sale',
	notReached: 'Not reached',
};

export const RESERVATION_SOURCE_MARKETING = 'marketing';
export const RESERVATION_SOURCE_MARKETINGLEAD = 'marketinglead';
export const RESERVATION_SOURCE_SALESMANAGER = 'salesmanager';
export const RESERVATION_SOURCE_SALESMAN = 'salesman';
export const RESERVATION_SOURCE_D2DMAN = 'd2Dman';
export const RESERVATION_SOURCE_TELEMAN = 'teleman';

export const reservationSourceAbbr = {
	[RESERVATION_SOURCE_MARKETING]: 'Request [abbr]',
	[RESERVATION_SOURCE_MARKETINGLEAD]: 'MarketingLead [abbr]',
	[RESERVATION_SOURCE_SALESMANAGER]: 'Salesperson [abbr]',
	[RESERVATION_SOURCE_SALESMAN]: 'Salesperson [abbr]',
	[RESERVATION_SOURCE_D2DMAN]: 'Appointment booker [abbr]',
	[RESERVATION_SOURCE_TELEMAN]: 'Telemarketing representative [abbr]',
};

export const reservationSource = {
	[RESERVATION_SOURCE_MARKETING]: 'Reservation source: marketing',
	[RESERVATION_SOURCE_MARKETINGLEAD]: 'Reservation source: marketinglead',
	[RESERVATION_SOURCE_SALESMANAGER]: 'Reservation source: salesmanager',
	[RESERVATION_SOURCE_SALESMAN]: 'Reservation source: salesman',
	[RESERVATION_SOURCE_D2DMAN]: 'Reservation source: d2dman',
	[RESERVATION_SOURCE_TELEMAN]: 'Reservation source: teleman',
};
