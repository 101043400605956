import {
	curry,
	pipe,
	filter,
	pathOr,
	range,
	groupBy,
	assoc,
	map,
	ascend,
	contains,
	find,
	sortBy,
	partition,
} from 'ramda';
import {sort} from 'utils/arrays';
import {getDay, getWeekBoundaries, getUtcDay} from './time';
import * as calResDicts from 'dicts/calendarResources';
import {additionalInfos} from 'dicts/projectSales';
import {appointmentsHourRange} from 'constants/domain';
import {over} from 'utils/lenses';
import areaTitle from 'utils/areaTitle';
import services from 'services';

let intl = null;
services.waitFor('intl').then(x => (intl = x));

export const findRoleByTypes = (types, roles) =>
	find(r => contains(r.type, types), roles);

export const reserverRoleLabel = item => {
	const translationKey =
		calResDicts.reservationSourceAbbr?.[item.reservationSource] || null;

	return translationKey ? intl.formatMessage({id: translationKey}) : null;
};

export const isHourless = res => res.dateFrom.getUTCHours() === 0;

export const isHourlesss = res => res.callAt.getUTCHours() === 0;

export const type = item =>
	// prettier-ignore
	!(item.buildingId || item.clientId) ? 'free'
	: isHourless(item) ? 'bonus'
	: !item.salesmanVisitId ? 'reserved'
	: 'completed';

export const state = ({type, ...rest}) =>
	// prettier-ignore
	type === 'free' || type === 'callReminder' ?
		null
	: ['reserved', 'bonus'].includes(type) ?
		rest.salesmanId ? 'allocated' : 'unallocated'
	:
		rest.salesmanVisit.state;

export const projectState = ({type, reservation, latestActivity, ...rest}) =>
	// prettier-ignore
	type === 'reserved' && reservation?.additionalInfo ?
		additionalInfos[reservation.additionalInfo.id]
	: type === 'completed' ?
			latestActivity ? latestActivity.state : rest.salesmanVisit.state
	: null;

export const createResourceTitle = (
	intl,
	{
		type,
		virtual = false,
		allocated = false,
		cancelled = false,
		salesmanVisitState = null,
		seen = false,
		projectState = null,
	},
) => {
	const base = intl.formatMessage({
		id:
			projectState && calResDicts.projectState[projectState]
				? calResDicts.projectState[projectState]
				: type === 'completed'
				? calResDicts.salesmanVisitState[salesmanVisitState]
				: type === 'reminder'
				? 'Reminder'
				: calResDicts.type[type],
	});
	// prettier-ignore
	const reservedState =
			type === 'reserved' ? `, ${intl.formatMessage({id: allocated ? 'allocated' : 'no salesperson'})}`
			: type === 'bonus' && allocated ? `, ${intl.formatMessage({id: 'allocated'})}`
				: type === 'reminder' && seen ? `, ${intl.formatMessage({ id: 'seen' })}` : '';
	const extParts = [
		...(type === 'reserved' && cancelled ? [intl.formatMessage({id: 'cancelled'})] : []),
		...(virtual ? [intl.formatMessage({id: 'moved to'})] : []),
	];
	const ext = extParts.length ? ` (${extParts.join(', ')})` : '';
	return base + reservedState + ext;
};

export const resourceTitle = curry((intl, res, isProjectSales = false) =>
	createResourceTitle(intl, {
		type: res.type,
		virtual: !!res.virtual,
		allocated: !!res.salesmanId,
		cancelled: !!res.cancelled,
		salesmanVisitState: res.salesmanVisit && res.salesmanVisit.state,
		projectState: isProjectSales ? projectState(res) : null,
	}),
);

// note: no practical need to sort by times, order will currently be correct as returned by api

export const getFree = filter(x => x.type === 'free');

export const getBonus = filter(x => x.type === 'bonus');

// scope allocated bonuses for given user
export const scopeAllocatedBonus = (items, userId) =>
	filter(
		x => (x.type === 'bonus' && x.state === 'allocated' ? x.salesmanId === userId : true),
		items,
	);

const reservedPrios = {unallocated: 1, allocated: 2};

export const getReserved = pipe(
	filter(x => x.type === 'reserved'),
	sort(ascend(i => reservedPrios[i.state] + (i.cancelled ? 2 : 0))),
);

const completedPrios = {
	notReached: 1,
	contacted: 2,
	offer: 3,
	deal: 4,
	cancelledDeal: 5,
	cancelledOffer: 6,
};

export const getCompleted = pipe(
	filter(x => x.type === 'completed'),
	sort(ascend(i => completedPrios[i.state])),
);

export const formatIntoWeekdaysAndHours = curry((weekSample, hourRange, items) => {
	const [weekStart, weekEnd] = getWeekBoundaries(weekSample);
	const hours = range(hourRange[0], hourRange[1] + 1);

	// filter out the items that are actually within the selected week
	const concreteItems = filter(
		i => i.dateFrom >= weekStart && i.dateFrom <= weekEnd,
		items,
	);

	// for items that have been recently moved, make virtual copies of them at their previous positions
	const virtualItems = pipe(
		filter(i => i.previousDateFrom >= weekStart && i.previousDateFrom <= weekEnd),
		map(assoc('virtual', true)),
	)(items);

	const group = (dateProp, items) =>
		pipe(
			groupBy(i => getDay(i[dateProp])),
			map(groupBy(i => i[dateProp].getHours())),
		)(items);

	const concreteGrouped = group('dateFrom', concreteItems);
	const virtualGrouped = group('previousDateFrom', virtualItems);

	return range(0, 7).map(iDay =>
		hours.reduce(
			(acc, hour) => {
				const getItems = pathOr([], [iDay, hour]);
				const getExtraItems = (items, hRange) => {
					let extraItems = [];
					hRange.forEach(h => {
						extraItems = [...extraItems, ...pathOr([], [iDay, h], items)];
					});
					return extraItems;
				};

				// we add items from 01:00 - 07:00 to 08:00 slot
				// and items from 21:00 - 23:00 to 20:00 slot
				// 00:00 slot is for bonus items
				// prettier-ignore
				const extraHoursRange =
					hour === appointmentsHourRange[0] ? range(1, appointmentsHourRange[0])
					: hour === appointmentsHourRange[1] ? range(appointmentsHourRange[1] + 1, 24)
					: [];

				const items = [
					...getItems(concreteGrouped),
					...getItems(virtualGrouped),
					...getExtraItems(concreteGrouped, extraHoursRange),
					...getExtraItems(virtualGrouped, extraHoursRange),
				];

				return {...acc, [hour]: items};
			},
			{},
			hours,
		),
	);
});

export const formatCallRemindersIntoWeekdaysAndHours = curry(
	(weekSample, hourRange, items) => {
		const [weekStart, weekEnd] = getWeekBoundaries(weekSample);
		const hours = range(hourRange[0], hourRange[1] + 1);

		// filter out the items that are actually within the selected week
		const concreteItems = filter(
			i => i.callAt >= weekStart && i.callAt <= weekEnd,
			items,
		);

		const group = (dateProp, items) =>
			pipe(
				groupBy(i => getDay(i[dateProp])),
				map(groupBy(i => i[dateProp].getHours())),
			)(items);

		const concreteGrouped = group('callAt', concreteItems);

		return range(0, 7).map(iDay =>
			hours.reduce(
				(acc, hour) => {
					const getItems = pathOr([], [iDay, hour]);
					const getExtraItems = (items, hRange) => {
						let extraItems = [];
						hRange.forEach(h => {
							extraItems = [...extraItems, ...pathOr([], [iDay, h], items)];
						});
						return extraItems;
					};

					// we add items from 00:00 - 07:00 to 08:00 slot
					// and items from 21:00 - 23:00 to 20:00 slot
					// prettier-ignore
					const extraHoursRange =
					hour === appointmentsHourRange[0] ? range(0, appointmentsHourRange[0])
					: hour === appointmentsHourRange[1] ? range(appointmentsHourRange[1] + 1, 24)
					: [];

					const items = [
						...getItems(concreteGrouped),
						...getExtraItems(concreteGrouped, extraHoursRange),
					];

					return {...acc, [hour]: items};
				},
				{},
				hours,
			),
		);
	},
);

export const normalizeItem = pipe(
	i => assoc('state', state(i), i),
	over(['areas'], sortBy(areaTitle)),
);

export const groupHourlessItems = is => {
	const grouped = groupBy(i => getUtcDay(i.dateFrom), is);
	return map(iDay => grouped[iDay] || [], range(0, 7));
};

export const normalizeCalendarResources = (weekStart, items) => {
	const [hourlessItems, hourItems] = partition(isHourless, map(normalizeItem, items));
	return [
		groupHourlessItems(hourlessItems),
		formatIntoWeekdaysAndHours(weekStart, appointmentsHourRange, hourItems),
	];
};

export const normalizeItems = pipe(
	i => assoc('state', state(i), i),
	over(['areas'], sortBy(areaTitle)),
);

export const normalizeCallReminders = (weekStart, items = []) => {
	return [
		formatCallRemindersIntoWeekdaysAndHours(weekStart, appointmentsHourRange, items),
	];
};

export const isSalesAssignment = resource =>
	resource.reservation &&
	resource.reservation.encounter &&
	resource.reservation.encounter.state === 'salesAssignment';

export const projectStateLabel = state =>
	// prettier-ignore
	state === 1 ? '1'
	: state === 2 ? '2'
	: state === 3 ? 'I'
	: state === 'offer' ? 'T'
	: state === 'deal' ? 'K'
	: state === 'cancelledOffer' ? 'H'
	: null;

// some CR details (address, client) are hidden if:
// a.) user is salesman and CR isn't visible for salesman
// b.) CR's date is after team calendar's lockFrom and reservationSource is teleman
export const hideResourceDetails = ({
	resource = {},
	isSalesman = false,
	lockFrom,
	canViewLockedTeamCalendar = false,
}) => {
	if (isSalesman && !resource.visibleForSalesman) {
		return true;
	}

	if (
		lockFrom &&
		resource.dateFrom >= lockFrom &&
		resource.reservationSource === 'teleman' &&
		!canViewLockedTeamCalendar
	) {
		return true;
	}

	return false;
};
